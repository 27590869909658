@import '../../checkout.module.scss';

.h1 {
  @include word-wrap();
  @include set-responsive-font-sizes(31, 35, 42);
   margin-bottom: $spacing-m;

  @include below-screen-sm {
     margin-top: $spacing-m;
    line-height: 2.5rem;
  }

  > span {
    display: block;
    font-size: inherit;
     font-weight: $font-weight-bold;
  }
}

.h3 {
  @include word-wrap();
  @include set-responsive-font-sizes(18, 20, 24);
   font-weight: $font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
   font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.bottomMargin {
   margin-bottom: $spacing-m;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  padding: 0 !important;
  border: none !important;

  @include below-screen-sm {
    display: none;
  }
}

.editButton:hover {
  color: #000;
   background-color: $gb_black_08;
}

.cartFooter {
  min-height: 74px !important;
   background: $gb_grey_050;

  @include below-screen-sm {
     padding-top: $spacing-xs;
     padding-bottom: $spacing-xs;
  }

  &Text {
    @include below-screen-sm {
       margin-bottom: $spacing-s;
    }
  }
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.basketLine {
  margin-top: 6.25rem;
   margin-bottom: $spacing-m;
}

.formHeading {
  margin-bottom: 0;

  @include below-screen-sm {
    margin-bottom: 0.75rem;
  }
}

.formExplanationText {
  text-align: right;
   color: $gb_grey_600;

  @include below-screen-sm {
    text-align: left;
  }
}
