 @import '../../checkout.module.scss';

.form {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  padding: 0 !important;
  border: none !important;

   @include below-screen-sm {
    display: none;
   }
}

.editButton:hover {
   color: $gb_black;
   background-color: $gb_black_08;
}

.deliveryCheckboxWrapper {
  label {
    cursor: pointer;
    position: relative;
  }
}

.formLeftElement {
  margin-bottom: 3.75rem;
  padding-right: 0.9375rem;

   @include below-screen-sm {
    padding-right: 0;
   }
}

.formRightElement {
  margin-bottom: 3.75rem;
  padding-left: 0.9375rem;

   @include below-screen-sm {
    padding-left: 0;
   }
}

.subTitle {
  margin-bottom: 2.1875rem;
  font-size: 1.125rem;
  font-weight: 600;
}
