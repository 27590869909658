@import '../../checkout.module.scss';

.formikFieldWrapper {
  position: relative;
  margin: 0;
  input:disabled {
    background: #e0e0e0;
    pointer-events: none;
  }
}

.error {
  position: absolute;
  bottom: -1.5rem;
  color: $gb_brand-utility-negative;
  font-size: 0.75rem;
}

.inputPlaceholder {
  z-index: 10;
  position: absolute;
  top: 0.75rem;
  left: 1.375rem;
  color: $gb_grey_400;
  pointer-events: none;
  -webkit-transition:
    font-size 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
  transition:
    font-size 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
  transition:
    transform 0.15s ease-in-out,
    font-size 0.15s ease-in-out;
  transition:
    transform 0.15s ease-in-out,
    font-size 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.inputPlaceholderFilled {
  font-size: 0.5625rem;
  -webkit-transform: translateY(-0.625rem);
  transform: translateY(-0.625rem);
}

.inputError {
  border: 0.0625rem solid $gb_brand-utility-negative !important;
}
