@import '../../checkout.module.scss';

.cartTableWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.scrollableArea {
  position: relative;
  height: 100%;

  @include below-screen-sm {
    overflow-x: scroll;
  }

  width: 100%;
}

.productImage {
  min-width: 41px;
  min-height: 41px;
  max-width: none;
}

.discountAmount {
  color: $gb_saturated_green;
}

.cartTable {
  width: 100%;

  @include below-screen-sm {
    min-width: 771px;
  }
}

.th {
  vertical-align: middle;
  text-align: center;
}

.td {
  vertical-align: middle;
  text-align: center;

  > div {
    display: inline-flex;
    flex-direction: column;
    gap: .2em;
    text-align: end;
  }
}

.tdWithoutBottomBorder {
  border-bottom: none;
}

.bottomMargin {
  margin-bottom: $spacing-m;
}

.arrowContainer {
  background: white;
  display: none;
  font-size: 1.1rem;

  &:focus-visible {
    outline: var(--gb-brand-primary) 2px solid;
  }

  @include below-screen-sm {
    display: flex;
  }
}

.gridContainer {
  justify-content: flex-start;
}
