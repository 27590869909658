@import '../../checkout.module.scss';

.c_breadcrumb {
  display: block;
  margin-bottom: $spacing-xs;
  margin-top: $spacing-xs;
  margin-left: 0.65rem;

  @include below-screen-xs {
    display: none;
  }

  ol {
    align-items: center;
    display: flex;
    margin: 0;
  }

  &__item {
    align-items: center;
    display: inline-flex;
    list-style: none;
    padding: 0;

    &::before {
      content: none;
    }

    a::before {
      content: none;
    }

    a {
      color: $gb_grey_650 !important;
      cursor: pointer;
      font-size: 0.875rem;
    }

    &:not(:first-child)::before {
      @include web20-icon('breadcrumb-arrow');
      color: $gb_grey_400;
      font-size: 0.875rem;
      padding: 0 0.5rem 0 0.5rem;
      position: relative;
      top: 1px;
    }

    &:visited {
      color: $gb_grey_650;
    }

    &__home a {
      color: $gb_grey_650 !important;
      cursor: pointer;
      margin-bottom: 3px;

      &::before {
        content: none;
      }

      .web20-icon-breadcrumb-home {
        bottom: 2px;
        position: relative;

        &::before {
          color: $gb_grey_650 !important;
          font-size: 1rem;
        }
      }
    }

    &--disabled,
    &--disabled a {
      color: $gb_grey_400;
      font-size: 0.9375rem;
      line-height: 1.6;
    }
  }
}
