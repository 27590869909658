@import '../../checkout.module.scss';

.bottomMargin {
  margin-bottom: $spacing-m;
}

.errorContainer {
  width: 100%;
  padding: $spacing-s;
  min-height: 60px;
  background-color: #f09292;

  > i {
    margin-right: $spacing-xs;
    font-size: x-large;
  }
}
